import { defineMessages } from "react-intl";

export default defineMessages({
  admin: {
    id: "menu.admin",
    defaultMessage: "Administrer",
  },
  logout: {
    id: "a2vote.logout",
    defaultMessage: "Logout"
  },
  profile: {
    id: "a2vote.profile",
    defaultMessage: "Profile"
  },
  complete:{
    id: "a2vote.complete_profile",
    defaultMessage: "Complete your profile"
  }
});