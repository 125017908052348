//React
import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Tools
import styled from "styled-components";
import theme from 'theme';
import { navigate } from "gatsby";
//Components
import A2Logo from 'components/A2Logo';
//Actions
import { doLogout } from "actions/login";
import UserMenu from "../../HomeVote/HomeVoteHeader/UserMenu";

export const TOPBAR_HEIGHT = "55px";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.ui.grey};
  padding: 16px 32px;

  @media(max-width: 900px) {
    padding: 16px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${theme.darkgray};

  @media(max-width: 900px) {
    display: none;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Welcome = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${theme.darkgray};
  cursor: pointer;

  @media(max-width: 900px) {
    font-size: 18px;
  }
`;

const TopBarLayout = () => {
  const { title } = useSelector((state) => state.title);
  const dispatch = useDispatch();
  const logout = () => dispatch(doLogout());

  return (
    <Container>
      <LeftSide>
        <Welcome onClick={() => navigate("/")}>
          <A2Logo small/>
        </Welcome>
        <Title>
          {title}
        </Title>
      </LeftSide>
      <UserMenu />
    </Container>
  );


}

export default TopBarLayout;