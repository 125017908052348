import * as React from "react"
// Components
import TopBarLayout from '../containers/Layout/Topbar/topBar';
import Button from '../components/Button/Button';
// Tools
import styled from "styled-components";
import theme from "theme";
// Utils
import Error404 from "images/pictures/404.svg";
import { navigate } from "gatsby";

const Container = styled.div`
  height: 100vh;
`;

const ErrorContainer = styled.div`
  height: calc(100% - 199px);
  padding: 64px 128px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > button {
  }

  @media(max-width: 768px) {
    width: 100%;

    & > button {
    }
  }
`;

const Title = styled.div`
  color: ${theme.darkgray};
  font-weight: bold;
  font-size: 32px;
  text-align: center;
`;

const Descriptions = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const Description = styled.div`
  color: ${theme.mediumgray};
  font-size: 18px;
  text-align: center;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;

  & > img {
    width: 70%;
  }
`;

const NotFoundPage = () => {
  return (
    <Container>
      <TopBarLayout />
      <ErrorContainer>
        <LeftSide>
          <Title>Oups ! Page non trouvée.</Title>
          <Descriptions>
            <Description>Cette page n'existe pas !</Description>
            <Description>Nous vous suggérons de retourner à l'accueil.</Description>
          </Descriptions>
          <Button onClick={() => navigate("/")}>
            Retour à l'accueil
          </Button>
        </LeftSide>
        <Image>
          <img src={Error404} alt="" />
        </Image>
      </ErrorContainer>
    </Container>
  )
}

export default NotFoundPage
