import React, { useState, useEffect } from 'react';
// tools
import theme from 'theme';
import styled from "styled-components";
// Redux
import { useSelector } from "react-redux";

const UserTitle = styled.div`
  margin: 0;
  color: ${theme.darkgray};
  font-size: 16px;

  @media(max-width: 768px) {
    font-size: 14px;
  }

  @media(max-width: 420px) {
    display: none;
  }
`;

const Username = () => {
  const [usertitle, setUsertitle] = useState('');
  const { fetchingUpdateUser } = useSelector((state) => state.user);
  useEffect(()=>{
    let userFront = null;
    try {
      userFront = typeof window !== 'undefined' && JSON.parse(localStorage?.getItem("userFront"));
    }
    catch (e) {
      console.error("not good local storage");
    }
    if(userFront?.firstname || userFront?.lastname) {
      setUsertitle(`${userFront?.lastname} ${userFront?.firstname}`);
    }
    else {
      setUsertitle(userFront?.login);
    }
  }, [fetchingUpdateUser])


  return (
    <UserTitle>
      {usertitle}
    </UserTitle>
  )
}

export default Username;