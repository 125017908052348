import React, { useState } from "react";
import { useDispatch } from "react-redux";
// tools
import theme from 'theme';
import styled from "styled-components";
import { navigate } from "gatsby";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
// components
import LanguageSwitcher from "components/LanguageSwitcher";
import Username from "./Username";
import Button from '../../../components/Button/Button';
// actions
import { doLogout } from "actions/login";
// utils
import Icon from 'utils/icon';

const UserMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  user-select: none;
`;

const Profil = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;

  @media(max-width: 900px) {
    display: flex;
    flex-flow: column-reverse;
    gap: 8px;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: ${theme.ui.blue};

  @media(max-width: 900px) {
    height: 32px;
    width: 32px;
  }
`;

const MenuSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 64px;
  right: 32px;
  color: ${theme.darkgray};
  background-color: ${theme.white};
  border: 1px solid ${theme.ui.grey};
  border-radius: 5px;
  box-shadow: 0px 4px 20px 0px rgba(184,184,184,0.25);
  z-index: 1;

  @media(max-width: 900px) {
    right: 16px;
    top: 64px;
    z-index: 2;
  }
`;

const Option = styled.div`
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  transition: all 300ms;

  &:hover {
    background: ${theme.darkwhite};
  }
`;

const Disconnect = styled(Option)`
  border-bottom: 1px solid ${theme.ui.grey};
  &:hover {
    background-color: ${theme.lightred};
  }
`;

const OptionText = styled.div`
  padding: 16px 32px;
  text-align: center;
  display: flex;
  gap: 8px;
`;

const Language = styled.div`
  padding: 16px 0;
`;

const ToggleButton = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
`;

const ButtonStyled = styled.div`
  display: flex;
  border-radius: 5px;
  flex-flow: row;
  align-items: center;
  animation: 2s linear 2s wiggle;
  border: 1px solid ${theme.orange};
  background-color: ${theme.lightorange};

  /* Keyframes */
  @keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-2deg);
    }
    20% {
      transform: rotateZ(2deg);
    }
    25% {
      transform: rotateZ(-4deg);
    }
    30% {
      transform: rotateZ(2deg);
    }
    35% {
      transform: rotateZ(-2deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }

  @media(max-width: 900px) {
    display: none;
  }
`;

const IconDiv = styled.div`
  background-color: ${theme.orange};
  padding: 4px;
  margin-left: 4px;
  border-radius: 4px;
  border: 1px solid ${theme.orange};
`;

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const logout = () => dispatch(doLogout());

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  const isAdmin = () => {
    try {
      return JSON.parse(localStorage.getItem('userFront'))?.admin === true;
    }
    catch(e) {
      return false;
    }
  }

  const isComplete = () => {
    try {
      const user = JSON.parse(localStorage.getItem('userFront'));
      return user?.firstname && user?.lastname;
    }
    catch(e) {
      return false;
    }
  }

  return (
    <UserMenuContainer>
      <Profil>
        {
          !isComplete() && /* si le profil n'est pas complet */
          <ButtonStyled onClick={() => navigate("/profile")}>
            <IconDiv>
              <Icon icon="Alert" size="sm" />
            </IconDiv>
            <Button backgroundColor={"lightorange"} textColor={"darkgray"}><FormattedMessage {...messages.complete} /></Button>
          </ButtonStyled>
        }
          <ToggleButton onClick={toggleMenu}>
            <Username />
            <Menu>
              <Icon icon="UserWhite" size="md" />
            </Menu>
          </ToggleButton>
      </Profil>
      {
        isOpen &&
          <MenuSelect isOpen={isOpen}>
            <Option onClick={() => navigate("/profile")}>
              <OptionText>
                <Icon icon="UserBlack" size="sm" />
                <FormattedMessage {...messages.profile} />
              </OptionText>
            </Option>
            {
              isAdmin() &&
                <Option onClick={() => navigate("/admin")}>
                  <OptionText>
                    <Icon icon="Cog" size="sm" />
                    <FormattedMessage {...messages.admin} />
                  </OptionText>
                </Option>
            }
            <Disconnect onClick={logout}>
              <OptionText>
                <Icon icon="Logout" size="sm" />
                <FormattedMessage {...messages.logout} />
              </OptionText>
            </Disconnect>
            <Language>
              <LanguageSwitcher />
            </Language>
          </MenuSelect>
      }
    </UserMenuContainer>
  )
}

export default UserMenu;