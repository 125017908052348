//React
import React from 'react';
import { useDispatch, useSelector } from "react-redux";

// Tools
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import styled from 'styled-components';

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '2px',
    maxHeight: '24px',
    cursor: "pointer"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingRight: '0px',
    display: 'flex',
    justifyContent: 'center',
    cursor: "pointer"
  }),
  control: (provided) => ({
    ...provided,
    width: "60px",
    height: "40px"
  }),
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const OPTIONS = [
  {label: <ReactCountryFlag countryCode="FR" style={{ borderRadius: '50%', width: '24px', height: '24px', cursor: 'pointer' }} svg/>, value:"fr"},
  {label: <ReactCountryFlag countryCode="GB" style={{ borderRadius: '50%', width: '24px', height: '24px', cursor: 'pointer' }} svg/>, value:"en"}
]

function localeToFlag(locale) {
  if(locale == 'en') {
    return 'gb';
  }
  return locale;
}

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.languageReducer);
  
  return (
    <Container>
      <Select
        options={OPTIONS}
        onChange={(option) => {
          dispatch({ type: 'change_locale', payload: {locale: option.value} })
        }}
        placeholder={<ReactCountryFlag countryCode={localeToFlag(locale)} style={{ borderRadius: '50%', width: '18px', height: '18px' }} svg/>}
        isSearchable={false}
        components={{ IndicatorSeparator:() => null }}
        styles={customStyles}
      />
    </Container>
  )
}

export default LanguageSwitcher;
